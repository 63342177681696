import { Pipe, PipeTransform } from '@angular/core';

/*
 * 数値を [値 + "名"] の文字列で返す
 */

@Pipe({name: 'humanCount'})
export class HumanCountPipe implements PipeTransform {
  transform(value: number): string {
    return value + "名";
  }
}