import { Pipe, PipeTransform } from '@angular/core';

/*
 * 予約の状況を返す
 * 0:予約申込 1:確定 2:キャンセル 99:削除
 */

@Pipe({name: 'calendarStatusCheck'})
export class CalendarStatusCheckPipe implements PipeTransform {
  transform(value: string): string {
    status = "";
    switch (value) {
      // default
      case "":
      status = "01";
        break;
      // 営業日
      case "1":
      status = "01";
        break;
      // 気をつけてください
      case "2":
      status = "02";
        break;
      // お休み
      case "3": 
      status = "03";
        break;
      // 過去の日付
      case "00":
      status = "00";
        break;
      // 未定
      case "98":
        status = "98";
        break;
      // その他
      case "99":
        status = "99";
        break;
    }
    return status;
  }
}

/*
 * 予約の状況用のClass名を返す
 * 0:予約申込 1:確定 2:キャンセル 99:削除
 */

@Pipe({name: 'calendarStatusClassCheck'})
export class CalendarStatusClassCheckPipe implements PipeTransform {
  transform(value: number): string {
    status = "";
    switch (value) {
      case 0:
      status = "";
      break;
      case 20:
      status = "";
      break;
      case 90:
      status = "";
      break;
      case 100:
      status = "";
      break;
      case 998:
      status = "";
      break;
      case 999:
      status = "";
      break;
    }
    return status;
  }
}