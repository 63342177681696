import { Pipe, PipeTransform } from '@angular/core';

/*
 * 数値を [値 + "回"] の文字列で返す
 */

@Pipe({name: 'count'})
export class CountPipe implements PipeTransform {
  transform(value: number): string {
    return value + "回";
  }
}