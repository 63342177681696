import { Component, OnInit, Inject, LOCALE_ID, Output, EventEmitter } from '@angular/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html'
})
export class CalendarComponent implements OnInit {

  @Output() event = new EventEmitter<string>();

  // カレンダー名
  calendarTitle: String;

  // 今日
  now: Date;

  // 選択時
  current: Date;
  nextMonth: Date;
  prevMonth: Date;

  // 今月内か否か
  outsideDayArray: {key?: string;} = {};
  
  // 日付
  dayArray: {key?: string;} = {};

  // 値
  valueArray: {key?: string;} = {};

  // 最後の 1 行を表示するか否か
  hideLastLine: boolean = true;

  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit() {
    this.calendarTitle = "2020年1月";
    this.now = new Date();
    var y_ = this.now.getFullYear();
    var m_ = this.now.getMonth() + 1;
    this.current = new Date(y_, m_, 0);

    // 翌月を取得
    this.getNextMonth();

    // 前月を取得
    this.getPreviousMonth();

    this.update();
  }

  setNextMonth() {
    this.current = this.nextMonth;
    this.getNextMonth();
    this.getPreviousMonth();
    this.update();
  }

  getNextMonth() {
    var nextYear = this.current.getFullYear();
    var nextMonth = this.current.getMonth() + 1;
    if (nextMonth == 12) {
      nextYear++;
      nextMonth = 1;
    } else {
      nextMonth++;
    }
    this.nextMonth = new Date(nextYear, nextMonth, 0);
  }

  setPreviousMonth() {
    this.current = this.prevMonth;
    this.getNextMonth();
    this.getPreviousMonth(); 
    this.update();
  }

  getPreviousMonth() {
    var prevYear = this.current.getFullYear();
    var prevMonth = this.current.getMonth() + 1;
    if (prevMonth == 1) {
      prevYear--;
      prevMonth = 12;
    } else {
      prevMonth--;
    }
    this.prevMonth = new Date(prevYear, prevMonth, 0);
  }

  update(){
    this.calendarTitle = formatDate(this.current, "yyyy年M月", this.locale);
    var y_ = this.current.getFullYear();
    var m_ = this.current.getMonth() + 1;
    var c_ = this.current.getDate();
    var d_ = new Date(y_, m_ - 1, 1).getDay();
    for (var i = 0; i < d_; i++) {
      this.outsideDayArray[0 + i] = true;
      this.dayArray[0 + i] = this.prevMonth.getDate() - i;
      this.valueArray[0 + i] = "";
    }
    for (var i = 0; i < c_; i++) {
      this.outsideDayArray[i + d_] = false;
      this.dayArray[i + d_] = i + 1;
      var z1_ = "0";
      var z2_ = "0";
      if (9 < m_) {
        z1_ = "";
      }
      if (9 <= i) {
        z2_ = "";
      }
      this.valueArray[i + d_] = String(y_) + z1_ + String(m_) + z2_ + String(i + 1);
    }
    for (var i = d_ + c_ ; i < 42; i++) {
      this.outsideDayArray[0 + i] = true;
      this.dayArray[i] = i - (d_ + c_ ) + 1;
      this.valueArray[i] = "";
    }
    this.hideLastLine = true;
    for (var i = 41; 34 < i; i--) {
      if (this.outsideDayArray[i] == false) {
        this.hideLastLine = false;
        break;
      }
    }
  }

  onClickDate(v: string) {
    if (v != "") {
      this.event.emit(v);
    }
  }

}