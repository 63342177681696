import { Pipe, PipeTransform } from '@angular/core';

/*
 * ご要望で何かあれば「あり」なければ「なし」を返す
 */

@Pipe({name: 'memoCheck'})
export class MemoCheckPipe implements PipeTransform {
  transform(value: string): string {
    if (value == "" || value == null) {
      return "なし";
    } else {
      return "あり";
    }
  }
}