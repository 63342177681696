import { Component, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { TabService } from '../../../services/tab.service';

// Models
import { Tab } from '../../../models/tab';

// Contents
import { CalendarSettingCategoryComponent } from '../../contents/calendar-setting-category/calendar-setting-category.component';
import { CalendarSettingShopComponent } from '../../contents/calendar-setting-shop/calendar-setting-shop.component';
import { CalendarSettingSettingComponent } from '../../contents/calendar-setting-setting/calendar-setting-setting.component';

@Component({
  selector: 'app-calendar-setting',
  templateUrl: './calendar-setting.component.html'
})
export class CalendarSettingPageComponent implements OnInit {
  
  // タブ
  currentTab: any;
  tabs: Array<Tab> = new Array();

  // タブの位置のclass名
  tabPosition = "spectrum-Tabs-selectionIndicator position-1";


  constructor(
    private tabService: TabService
  ) {}

  
  /* ===== LifeCycle ===== */
  
  // 開始処理
  ngOnInit() {
    
    // タブを設定
    this.tabs.push(new Tab('店舗・イベント', CalendarSettingShopComponent, true));
    this.tabs.push(new Tab('カテゴリ', CalendarSettingCategoryComponent, false));
    this.tabs.push(new Tab('設定', CalendarSettingSettingComponent, false));
    this.tabService.tabs = this.tabs;
    this.currentTab = this.tabService.getCurrentContents();
    
  }
  
  // 終了処理
  ngOnDestroy() {
  }
  
  
  /* ===== イベント ===== */
  
  // タブをクリックしたとき
  public onClickTabButton($event) {
    this.currentTab = this.tabService.changeCurrentContents($event.target.innerHTML);
    switch($event.target.innerHTML){
      case "店舗・イベント":
        this.tabPosition = "spectrum-Tabs-selectionIndicator position-1";
        break;
      case "カテゴリ":
        this.tabPosition = "spectrum-Tabs-selectionIndicator position-2";
        break;
      case "設定":
        this.tabPosition = "spectrum-Tabs-selectionIndicator position-3";
        break;
    }
  }

}
