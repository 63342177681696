import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Shopcategory } from '../../../models/shopcategory';

@Component({
  selector: 'calendar-setting-category-list-item',
  templateUrl: './calendar-setting-category-list-item.component.html'
})
export class CalendarSettingCategoryListItemComponent implements OnInit {

  @Output() event = new EventEmitter<any[]>();
  
  @Input() item: Shopcategory;

  constructor() { }

  ngOnInit() {
  }

  onEditFolder() {
    var data: any[] = new Array(2);
    data[0] = "edit";
    data[1] = this.item;
    this.event.emit(data);
  }

  onDeleteFolder() {
    var data: any[] = new Array(2);
    data[0] = "delete";
    data[1] = this.item;
    this.event.emit(data);
  }

}