import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  // コンポーネントから受け取った命令
  // order[0]:コンポーネント名
  // order[1]:命令がわかるもの
  public order: string[] = [];
  
  private alertEventSubject = new Subject<string[]>();
  public alertEventObservable$ = this.alertEventSubject.asObservable();

  // アラート内に実行ボタンを使う(true)か否(false)か
  public hasAction: boolean;
  public action: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor() {
    this.hasAction = false;
  }

  // アラートを操作する
  public requestAlert(data: string[]) {
    this.alertEventSubject.next(data);
  }

  // 実行ボタンを有効にする（true）か否（false）か
  public setAction(flg: boolean){
    this.hasAction = flg;
  }

  // アラートに命令を保持させるする
  public setOrder(order_: string[]) {
    this.order = order_;
  }

  // アラートが保持している命令を返す
  public getOrder() {
    return this.order;
  }

  // アラートが保持する命令を空にする
  public clearOrder() {
    this.order = [];
  }

  // アラート内の実行ボタンをクリックした場合
  public actionOnAlert(){
    if (this.hasAction) {
      this.action.emit(this.order);
    }
  }
  
}