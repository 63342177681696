import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  // コンポーネントから受け取った命令
  // order[0]:コンポーネント名
  // order[1]:命令がわかるもの
  public order: string[] = [];
  
  private dialogEventSubject = new Subject<string[]>();
  public dialogEventObservable$ = this.dialogEventSubject.asObservable();

  // ダイアログ内に実行ボタンを使う(true)か否(false)か
  public hasAction: boolean;
  public action: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor() {
    this.hasAction = false;
  }

  // ダイアログを操作する
  public requestDialog(data: string[]) {
    this.dialogEventSubject.next(data);
  }

  // 実行ボタンを有効にする（true）か否（false）か
  public setAction(flg: boolean){
    this.hasAction = flg;
  }

  // ダイアログに命令を保持させるする
  public setOrder(order_: string[]) {
    this.order = order_;
  }

  // ダイアログが保持している命令を返す
  public getOrder() {
    return this.order;
  }

  // ダイアログが保持する命令を空にする
  public clearOrder() {
    this.order = [];
  }

  // ダイアログ内の実行ボタンをクリックした場合
  public actionOnDialog(){
    if (this.hasAction) {
      this.action.emit(this.order);
    }
  }
  
}