import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Account } from '../models/account';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentAccountSubject: BehaviorSubject<Account>;
  public currentAccountModel: Observable<Account>;

  constructor(private http: HttpClient) {
    this.currentAccountSubject = new BehaviorSubject<Account>(JSON.parse(localStorage.getItem('account')));
    this.currentAccountModel = this.currentAccountSubject.asObservable();
  }

  public get currentAccount(): Account {
    return this.currentAccountSubject.value;
  }

  public login(username: string, password: string) {
    return this.http.post<any>(environment.apiUrl + '/auth', {username, password})
      .pipe(map(data => {
        if (data.status == "success" && data.role <= 10) {
          // ログインできて権限がある人
          localStorage.setItem('account', JSON.stringify(data));
          this.currentAccountSubject.next(data);
        } else if (data.status == "sucess") {
          // 権限がない人
          this.currentAccountSubject.next(data);
        } else if (data.status == "error") {
          // ログインに失敗
          this.currentAccountSubject.next(data);
        } else {
          // 不明だがとりあえず失敗
          this.currentAccountSubject.next(data);
        }
        return data;
      }));
  }
  
  public logout() {
    localStorage.removeItem('account');
    localStorage.removeItem("calendarSelectShopId");
    this.currentAccountSubject.next(null);
  }
  
}