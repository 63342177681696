  <div class="spectrum spectrum--medium spectrum--light">

    <div class="appContainer-left-rail">
    
      <div class="overlay"></div>
      
      <app-side-navigation></app-side-navigation>
      
      <main class="mainContainer">
        
        <div class="pageContainer">

          <router-outlet></router-outlet>

        </div><!-- /.pageContainer -->

      </main>

      <div [ngClass]="{'hide': alertMaskFlg}" class="mask"></div>

      <div [class]="alertClass">
        <div [class]="alertTypeClass" [ngSwitch]="alertType">
          <svg *ngSwitchCase="'info'" class="spectrum-Icon spectrum-UIIcon-InfoMedium spectrum-Alert-icon" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-css-icon-InfoMedium" />
          </svg>
          <svg *ngSwitchCase="'help'" class="spectrum-Icon spectrum-UIIcon-HelpMedium spectrum-Alert-icon" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-css-icon-HelpMedium" />
          </svg>
          <svg *ngSwitchCase="'success'" class="spectrum-Icon spectrum-UIIcon-SuccessMedium spectrum-Alert-icon" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-css-icon-SuccessMedium" />
          </svg>
          <svg *ngSwitchCase="'warning'" class="spectrum-Icon spectrum-UIIcon-AlertMedium spectrum-Alert-icon" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-css-icon-AlertMedium" />
          </svg>
          <svg *ngSwitchCase="'warning'" class="spectrum-Icon spectrum-UIIcon-AlertMedium spectrum-Alert-icon" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-css-icon-AlertMedium" />
          </svg>
          <div class="spectrum-Alert-header">{{alertHeader}}</div>
          <div class="spectrum-Alert-content">{{alertContent}}</div>
          <div class="spectrum-Alert-footer">
            <button [ngClass]="{'hide': alertActionButtonFlg}" class="spectrum-Button nd-Button-cancel" (click)="onClickAlertActionButton()">{{alertActionButtonLabel}}</button>
            <button class="spectrum-Button nd-Button-cancel" (click)="onClickAlertCancelButton()">{{alertCancelButtonLabel}}</button>
          </div>
        </div>
      </div>

      
      <div [ngClass]="{'is-open': dialogFlg}" class="spectrum-Dialog spectrum-Dialog--alert">
        <div class="spectrum-Dialog-header">
          <h2 class="spectrum-Dialog-title">{{dialogHeader}}</h2>
        </div>
        <div class="spectrum-Dialog-content">{{dialogContent}}</div>
        <div class="spectrum-Dialog-footer">
          <button class="spectrum-Button nd-Button-cancel" (click)="onClickDialogCancelButton()">{{dialogCancelButtonLabel}}</button>
          <button [ngClass]="{'hide': dialogActionButtonFlg}" class="spectrum-Button {{dialogClass}}" (click)="onClickDialogActionButton()">{{dialogActionButtonLabel}}</button>
        </div>
      </div>
      
    </div>
  </div>