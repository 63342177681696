import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html'
})
export class TagComponent implements OnInit {

  @Output() event = new EventEmitter<number>();

  private _id: number;
  private _v: string;
  private _type: string;
  _label: string;

  constructor() {}

  ngOnInit() {
    //
  }

  // クローズイベントのgetプロパティ
  public get deleteEvent(): Observable<{}>
  {
    return this.event;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get id() {
    return this._id;
  }

  public set v(value: string) {
    this._v = value;
  }

  public set type(value: string) {
    this._type = value;
  }

  public set label(value: string) {
    this._label = value;
  }

  onClickDelete() {
    this.event.emit(this._id);
  }

}