<div class="nd-row">
  
  <div class="nd-col-xs-12">

    <div class="nd-page-header nd-botton-margin">
      <div class="nd-page-header-title">
        <h1 class="spectrum-Heading1">カレンダー設定</h1>
      </div>
    </div><!-- /.nd-page-header -->

  </div><!-- /.nd-col-xs-12 -->
  
</div><!-- /.nd-row -->


<div class="nd-row">
  
  <div class="nd-col-xs-12">

    <div class="spectrum-Tabs spectrum-Tabs--horizontal">
      
      <ng-container *ngFor="let tab of tabs">
      
        <div class="spectrum-Tabs-item" [class.current]="tab.current" data-tab="customer-info">
          <label class="spectrum-Tabs-itemLabel" (click)="onClickTabButton($event)">{{tab.name}}</label>
        </div>
        
      </ng-container>

      <div [class]="tabPosition"></div>
    </div>
    
  </div><!-- /.nd-col-xs-12 -->

</div><!-- /.nd-row -->

<ng-container *ngComponentOutlet="currentTab"></ng-container>
