<div class="spectrum spectrum--medium spectrum--light">
    
  <div class="appContainer-center">
    
    <main class="mainContainer">
      
      <div class="pageContainer">
        
        <div class="nd-row">

          <div class="nd-col-xs-12">
  
            <div class="nd-page-header nd-botton-margin">
              <div class="nd-page-header-space"></div>
              <div class="nd-page-header-title">
                <h1 class="spectrum-Heading1">ログイン</h1>
              </div>
              <div class="nd-page-header-space"></div>
            </div><!-- /.nd-page-header -->
        
          </div><!-- /.nd-col-xs-12 -->
          
          <div class="nd-col-xs-12 nd-col-sm-12">
            <div class="nd-page-description">
              <p class="spectrum-Body3">アカウント名とパスワードを入力して「ログイン」ボタンを押してください。</p>
            </div>
          </div>

        </div>
        
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="spectrum-Form spectrum-Form--labelsAbove">

          <div class="nd-row nd-botton-margin">

            <div class="nd-col-xs-12 nd-col-sm-4">

              <div class="spectrum-Form-item">
                <label for="username" class="spectrum-Form-itemLabel">アカウント名</label>
                <input id="username" placeholder="" formControlName="username" value="" type="text" class="spectrum-Textfield">
              </div>

              <div class="spectrum-Form-item">
                <label for="password" class="spectrum-Form-itemLabel">パスワード</label>
                <input id="password" placeholder="" formControlName="password" value="" type="password" class="spectrum-Textfield">
              </div>
              
            </div>
            
          </div>
        
        
          <div class="nd-row">
          
            <div class="nd-col-xs-6">
              
              <div class="nd-botton-margin">
                <div class="spectrum-ButtonGroup">
                  <button [disabled]="!loginForm.valid" class="spectrum-Button nd-Button-register"><span class="spectrum-Button-label">ログイン</span></button>
                </div>
              </div>

            </div>

          </div>

        </form>

      </div>
      
    </main>

    <div [class]="alertClass">
      <div [class]="alertTypeClass" [ngSwitch]="alertType">
        <svg *ngSwitchCase="'info'" class="spectrum-Icon spectrum-UIIcon-InfoMedium spectrum-Alert-icon" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-css-icon-InfoMedium" />
        </svg>
        <svg *ngSwitchCase="'help'" class="spectrum-Icon spectrum-UIIcon-HelpMedium spectrum-Alert-icon" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-css-icon-HelpMedium" />
        </svg>
        <svg *ngSwitchCase="'success'" class="spectrum-Icon spectrum-UIIcon-SuccessMedium spectrum-Alert-icon" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-css-icon-SuccessMedium" />
        </svg>
        <svg *ngSwitchCase="'warning'" class="spectrum-Icon spectrum-UIIcon-AlertMedium spectrum-Alert-icon" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-css-icon-AlertMedium" />
        </svg>
        <svg *ngSwitchCase="'warning'" class="spectrum-Icon spectrum-UIIcon-AlertMedium spectrum-Alert-icon" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-css-icon-AlertMedium" />
        </svg>
        <div class="spectrum-Alert-header">{{alertHeader}}</div>
        <div class="spectrum-Alert-content">{{alertContent}}</div>
        <div class="spectrum-Alert-footer">
          <button [ngClass]="{'hide': alertActionButtonFlg}" class="spectrum-Button spectrum-Button--primary spectrum-Button--quiet" (click)="onClickAlertActionButton()">{{alertActionButtonLabel}}</button>
          <button class="spectrum-Button spectrum-Button--primary spectrum-Button--quiet" (click)="onClickAlertCancelButton()">{{alertCancelButtonLabel}}</button>
        </div>
      </div>
    </div>
    
  </div>
  
</div>