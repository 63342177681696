import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InViewportModule } from 'ng-in-viewport';

// Layout
import { LayoutComponent } from './presentation/layout/layout.component';
import { SideNavigationComponent } from './presentation/layout/side-navigation/side-navigation.component';
import { CircleLoaderComponent } from './presentation/layout/circle-loader/circle-loader.component';
import { CalendarComponent } from './presentation/layout/calendar/calendar.component';
import { TagComponent } from './presentation/layout/tag/tag.component';

// Pages
import { IndexComponent } from './presentation/pages/index/index.component';
import { HomeComponent } from './presentation/pages/home/home.component';
import { CalendarPageComponent } from './presentation/pages/calendar/calendar.component';
import { CalendarSettingPageComponent } from './presentation/pages/calendar-setting/calendar-setting.component';

// Contents
import { CalendarSettingCategoryComponent } from './presentation/contents/calendar-setting-category/calendar-setting-category.component';
import { CalendarSettingShopComponent } from './presentation/contents/calendar-setting-shop/calendar-setting-shop.component';
import { CalendarSettingSettingComponent } from './presentation/contents/calendar-setting-setting/calendar-setting-setting.component';
import { CalendarSettingCategoryListItemComponent } from './presentation/contents/calendar-setting-category-list-item/calendar-setting-category-list-item.component';
import { CalendarSettingShopListItemComponent } from './presentation/contents/calendar-setting-shop-list-item/calendar-setting-shop-list-item.component';

// Pipe
import { CountPipe } from './presentation/pipes/count.pipe';
import { HumanCountPipe } from './presentation/pipes/human-count.pipe';
import { MemoCheckPipe } from './presentation/pipes/memo-check.pipe';
import { CalendarDateFormatJPPipe } from './presentation/pipes/calendar.pipe';
import { CalendarStatusCheckPipe } from './presentation/pipes/calendar-status-check.pipe';
import { CalendarStatusClassCheckPipe } from './presentation/pipes/calendar-status-check.pipe';
import { CalendarMemoCheckPipe } from './presentation/pipes/calendar-memo-check.pipe';
import { CalendarShopCategoryStatusCheckPipe } from './presentation/pipes/calendar-shop-category-status-check.pipe';
import { ListDateFormatJPPipe } from './presentation/pipes/list-date.pipe';

// Helpers
import { AuthGuard } from './helpers/auth.guard';

const routes: Routes = [
  { path: "", component: IndexComponent, pathMatch: 'full' },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "home", component: HomeComponent },
      { path: "calendar", component: CalendarPageComponent },
      { path: "calendar/setting", component: CalendarSettingPageComponent },
    ]
  }
  //{ path: "admin", loadChildren: './presentation/pages/pages.module#PagesModule'},
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InViewportModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [
    IndexComponent,
    LayoutComponent,
    SideNavigationComponent,
    CircleLoaderComponent,
    CalendarComponent,
    HomeComponent,
    TagComponent,
    CalendarPageComponent,
    CalendarSettingPageComponent,
    CalendarSettingCategoryComponent,
    CalendarSettingShopComponent,
    CalendarSettingSettingComponent,
    CalendarSettingCategoryListItemComponent,
    CalendarSettingShopListItemComponent,
    CountPipe,
    HumanCountPipe,
    MemoCheckPipe,
    CalendarDateFormatJPPipe,
    CalendarStatusCheckPipe,
    CalendarStatusClassCheckPipe,
    CalendarMemoCheckPipe,
    CalendarShopCategoryStatusCheckPipe,
    ListDateFormatJPPipe
  ],
  entryComponents:[
    CalendarSettingCategoryComponent,
    CalendarSettingShopComponent,
    CalendarSettingSettingComponent,
    TagComponent
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule { }
