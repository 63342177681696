import { Component, OnInit } from '@angular/core';

// Service
import { LoadingService } from '../../../services/loading.service';
import { LogService } from '../../../services/log.service';

// Model
import { Log } from '../../../models/log';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  // ローディング
  circleLoaderClass = "hide";

  // コンテンツ
  contentsClass = "nd-col-xs-12 hide";

  // 記録一覧
  items: Log[];

  // リンクを持つか否かを管理する
  has_link: { [key: number]: boolean; } = {};

  // 追加読み込み中（true）か否（false）か
  nowLoading: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private logService: LogService,
  ) {}

  ngOnInit() {
    this.showLoading();
    this.getItems();
  }

  ngOnDestroy() {}

  /* ========== メソッド ========== */

  // ローディングを表示する
  showLoading() {
    this.circleLoaderClass = "show";
  }

  // ローディングを隠す
  hideLoading() {
    this.circleLoaderClass = "hide";
  }

  // コンテンツを表示する
  showContents() {
    this.contentsClass = "nd-col-xs-12 show";
  }

  // コンテンツを非表示する
  hideContents() {
    this.contentsClass = "nd-col-xs-12 hide";
  }

  // ユーザーの記録一覧を取得する
  getItems(){

    this.nowLoading = true;

    this.logService.getItems().subscribe(items => {
      this.items = items['items'];
      if (typeof this.items === "undefined") {
        console.log("No items");
      } else {
        for (var i = 0; i< this.items.length; i++) {
          if (this.items[i].type == 6) {
            this.has_link[this.items[i].id] = false;
          } else {
            this.has_link[this.items[i].id] = true;
          }
        }
      }
      this.nowLoading = false;
      this.hideLoading();
      this.showContents();
    });

  }

}
