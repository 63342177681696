<div class="spectrum-Calendar">
    <div class="spectrum-Calendar-header">
       <div class="spectrum-Calendar-title" role="heading" aria-live="assertive" aria-atomic="true">{{calendarTitle}}</div>
       <button (click)="setPreviousMonth()" id="calendar-previous-button" aria-label="Previous" title="Previous" class="spectrum-ActionButton spectrum-ActionButton--quiet spectrum-Calendar-prevMonth">
         <svg class="spectrum-Icon spectrum-UIIcon-ChevronLeftLarge" focusable="false" aria-hidden="true">
           <use xlink:href="#spectrum-css-icon-ChevronLeftLarge" />
         </svg>
       </button>
       <button (click)="setNextMonth()" id="calendar-next-button" aria-label="Next" title="Next" class="spectrum-ActionButton spectrum-ActionButton--quiet spectrum-Calendar-nextMonth">
         <svg class="spectrum-Icon spectrum-UIIcon-ChevronRightLarge" focusable="false" aria-hidden="true">
           <use xlink:href="#spectrum-css-icon-ChevronRightLarge" />
         </svg>
       </button>
    </div>
    <div class="spectrum-Calendar-body" role="grid" tabindex="0" aria-readonly="true" aria-disabled="false">
      <table role="presentation" class="spectrum-Calendar-table">
         <thead role="presentation">
            <tr role="row">
               <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Sunday">日</abbr></th>
               <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Monday">月</abbr></th>
               <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Tuesday">火</abbr></th>
               <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Wednesday">水</abbr></th>
               <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Thursday">木</abbr></th>
               <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Friday">金</abbr></th>
               <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Saturday">土</abbr></th>
            </tr>
         </thead>
         <tbody role="presentation">
            <tr role="row">
               <td (click)="onClickDate(valueArray[0])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[0]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[0]}}</span></td>
               <td (click)="onClickDate(valueArray[1])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[1]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[1]}}</span></td>
               <td (click)="onClickDate(valueArray[2])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[2]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[2]}}</span></td>
               <td (click)="onClickDate(valueArray[3])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[3]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[3]}}</span></td>
               <td (click)="onClickDate(valueArray[4])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[4]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[4]}}</span></td>
               <td (click)="onClickDate(valueArray[5])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[5]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[5]}}</span></td>
               <td (click)="onClickDate(valueArray[6])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[6]}}</span></td>
            </tr>
            <tr role="row">
               <td (click)="onClickDate(valueArray[7])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[7]}}</span></td>
               <td (click)="onClickDate(valueArray[8])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[8]}}</span></td>
               <td (click)="onClickDate(valueArray[9])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[9]}}</span></td>
               <td (click)="onClickDate(valueArray[10])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[10]}}</span></td>
               <td (click)="onClickDate(valueArray[11])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[11]}}</span></td>
               <td (click)="onClickDate(valueArray[12])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[12]}}</span></td>
               <td (click)="onClickDate(valueArray[13])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[13]}}</span></td>
            </tr>
            <tr role="row">
               <td (click)="onClickDate(valueArray[14])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[14]}}</span></td>
               <td (click)="onClickDate(valueArray[15])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[15]}}</span></td>
               <td (click)="onClickDate(valueArray[16])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[16]}}</span></td>
               <td (click)="onClickDate(valueArray[17])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[17]}}</span></td>
               <td (click)="onClickDate(valueArray[18])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[18]}}</span></td>
               <td (click)="onClickDate(valueArray[19])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[19]}}</span></td>
               <td (click)="onClickDate(valueArray[20])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[20]}}</span></td>
            </tr>
            <tr role="row">
               <td (click)="onClickDate(valueArray[21])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[21]}}</span></td>
               <td (click)="onClickDate(valueArray[22])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[22]}}</span></td>
               <td (click)="onClickDate(valueArray[23])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[23]}}</span></td>
               <td (click)="onClickDate(valueArray[24])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[24]}}</span></td>
               <td (click)="onClickDate(valueArray[25])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[25]}}</span></td>
               <td (click)="onClickDate(valueArray[26])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[26]}}</span></td>
               <td (click)="onClickDate(valueArray[27])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[27]}}</span></td>
            </tr>
            <tr role="row">
               <td (click)="onClickDate(valueArray[28])" class="spectrum-Calendar-tableCell"><span role="presentation" class="spectrum-Calendar-date">{{dayArray[28]}}</span></td>
               <td (click)="onClickDate(valueArray[29])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[29]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[29]}}</span></td>
               <td (click)="onClickDate(valueArray[30])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[30]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[30]}}</span></td>
               <td (click)="onClickDate(valueArray[31])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[31]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[31]}}</span></td>
               <td (click)="onClickDate(valueArray[32])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[32]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[32]}}</span></td>
               <td (click)="onClickDate(valueArray[33])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[33]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[33]}}</span></td>
               <td (click)="onClickDate(valueArray[34])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[34]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[34]}}</span></td>
            </tr>
            <tr [ngClass]="{'hide': hideLastLine}" role="row">
               <td (click)="onClickDate(valueArray[35])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[35]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[35]}}</span></td>
               <td (click)="onClickDate(valueArray[36])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[36]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[36]}}</span></td>
               <td (click)="onClickDate(valueArray[37])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[37]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[37]}}</span></td>
               <td (click)="onClickDate(valueArray[38])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[38]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[38]}}</span></td>
               <td (click)="onClickDate(valueArray[39])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[39]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[39]}}</span></td>
               <td (click)="onClickDate(valueArray[40])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[40]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[40]}}</span></td>
               <td (click)="onClickDate(valueArray[41])" class="spectrum-Calendar-tableCell"><span [ngClass]="{'is-outsideMonth': outsideDayArray[41]}" role="presentation" class="spectrum-Calendar-date">{{dayArray[41]}}</span></td>
            </tr>
         </tbody>
      </table>
    </div>
</div>