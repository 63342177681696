import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../services/auth.service';
import { DialogService } from '../../../services/dialog.service';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html'
})
export class SideNavigationComponent implements OnInit {

  // ダイアログの実行ボタンの状態を監視
  dialogSubscription: Subscription;

  // メニューが表示されているか否か
  sidebarOpen: boolean = false;

  // メニューが表示されているか否か
  sidebarShow: boolean = false;

  // ページの先頭
  pageYoffset: number = 0;


  constructor(
    private router: Router,
    private scroll: ViewportScroller,
    private authService: AuthService,
    private dialogService: DialogService
  ){}

  @HostListener('window:scroll', ['$event']) onScroll(event){
    this.pageYoffset = window.pageYOffset;
 }

  

  /* ========== ライフサイクル ========== */


  // コンポーネントを初期化
  ngOnInit() {

    // ダイアログの状態監視を始める
    this.dialogService.setAction(true);
    this.dialogSubscription = this.dialogService.action.subscribe((data: string[]) => {
      if(data[0] == "app-side-navigation") {
        console.log("【 side-navigation.component 】" + data[1] + " を実行します");
        switch(data[1]) {
          case "logout":
            this.logout();
            break;
        }
      } else {
        console.log("【 side-navigation.component 】実行しません");
      }
    });
  }

  // Observableの購読を解除し、イベントハンドラをデタッチ
  ngOnDestroy() {

    console.log("【 side-navigation.component 】onDestroy");
    
    // ダイアログの監視を解除する
    this.dialogService.setAction(false);
    this.dialogService.clearOrder;
    this.dialogSubscription.unsubscribe();

  }



  /* ========== イベント ========== */

  // ログアウトをクリックした場合
  onClickLogoutButton() {
    console.log("【 side-navigation.component 】onClickLogoutButton");
    this.showLogoutConfirmDialog();
  }

  // メニューボタンをクリックした場合
  onClickMenuButton() {
    var this_ = this;
    this.sidebarShow = true; 
    setTimeout(function(){this_.sidebarOpen = true;}, 100);
  }

  // 閉じるボタンをクリックした場合
  onClickCloseButton() {
    var this_ = this;
    this.sidebarOpen = false;
    setTimeout(function(){this_.sidebarShow = true;}, 600);
  }

  // リンクボタンをクリックした場合
  onSideNavItem() {
    if (this.sidebarShow) {
      // メニューを閉じる
      this.onClickCloseButton();
    } else {
      console.log("test");
    }
  }

  // ページの先頭に戻る
  scrollToTop(){
    this.scroll.scrollToPosition([0,0]);
  }

  /* ========== メソッド ========== */

  /** 
   * ログアウト確認ダイアログを表示する
   * array[
   *  アクションボタンを使う場合は実行時のボタンのラベル名,
   *  アクションボタンを使う場合はキャンセル時のボタンのラベル名,
   *  タイトル,
   *  説明文
   * ]
   */
  showLogoutConfirmDialog() {
    // ダイアログに命令を登録する
    var order: string[] = ["app-side-navigation", "logout"];
    this.dialogService.setOrder(order);
    var dialogArray: string[] = ['ログアウトする','ログアウトしない','ログアウトしますか?','「ログアウトする」をクリックするとこの端末で次ご利用になる場合にアカウントとパスワードの入力が必要です。','nd-Button-warning'];
    this.dialogService.requestDialog(dialogArray)
  }


  // ログアウト処理を実行する
  logout() {
    console.log("【 side-navigation.component 】logout");
    this.authService.logout();
    this.router.navigate(['/']);
  }

}
