import { Pipe, PipeTransform } from '@angular/core';

/*
 * メモ1とメモ2のHTML調整用の状況を返す
 */

@Pipe({name: 'calendarMemoCheck'})
export class CalendarMemoCheckPipe implements PipeTransform {
  transform(value: string): string {
    if (value == null){
      return "　";
    } else {
      return value;
    }
  }
}
