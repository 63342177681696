import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { LoadingService } from '../../services/loading.service';
import { AlertService } from '../../services/alert.service';
import { DialogService } from '../../services/dialog.service';
import { sharedStylesheetJitUrl } from '@angular/compiler';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {

  // アラートを管理する
  alertSubscription: Subscription;

  // アラートの表示(show)・非表示(hide)を管理するクラス
  alertClass = "hide";
  
  // アラートの種類
  alertType ="info";

  // アラートの種類に応じて決まるクラス
  alertTypeClass = "";

  // アラートの種類別をクラスを管理する配列
  alertTypeClassArray: { [key: string]: string; } = {};

  // アラートの見出し部分のテキスト
  alertHeader = "";

  // アラートの本文のテキスト
  alertContent = "";

  // アラートの閉じるボタンのラベル
  alertCancelButtonLabel = "";

  // アラートの実行ボタンを隠す(true)か否か(false)
  alertActionButtonFlg = true;

  // アラートの実行ボタンのラベル
  alertActionButtonLabel = "";

  // アラート・ダイアログ用のマスクを隠す(true)か否か(false)
  alertMaskFlg = true;

  // ダイアログを管理する
  dialogSubscription: Subscription;

  // ダイアログを表示する(true)か否か(false)
  dialogFlg = false;

  // ダイアログの見出し部分のテキスト
  dialogHeader = "";

  // ダイアログの本文のテキスト
  dialogContent = "";

  // ダイアログの閉じるボタンのラベル
  dialogCancelButtonLabel = "キャンセル";

  // ダイアログの実行ボタンを隠す(true)か否か(false)
  dialogActionButtonFlg = true;

  // ダイアログの実行ボタンのラベル
  dialogActionButtonLabel = "実行する";

  // ダイアログの種類を示すクラス
  dialogClass = "";


  constructor(
    private alertService: AlertService,
    private dialogService: DialogService
  ) {
    this.alertTypeClassArray['info'] = "spectrum-Alert spectrum-Alert--success";
    this.alertTypeClassArray['help'] = "spectrum-Alert spectrum-Alert--help";
    this.alertTypeClassArray['success'] = "spectrum-Alert spectrum-Alert--success";
    this.alertTypeClassArray['warning'] = "spectrum-Alert spectrum-Alert--warning";
    this.alertTypeClassArray['error'] = "spectrum-Alert spectrum-Alert--error";
    this.alertTypeClass = this.alertTypeClassArray[this.alertType];
  }



  /* ========== ライフサイクル ========== */

  // コンポーネントを初期化
  ngOnInit() {

    // アラートを管理する
    this.alertSubscription = this.alertService.alertEventObservable$.subscribe(
      (data) => {
        this.alertTypeClass = this.alertTypeClassArray[data[0]];
        this.alertHeader = data[3];
        this.alertContent = data[4];
        if (data[1] == "") {
          this.alertActionButtonFlg = true;
          this.alertCancelButtonLabel = "閉じる";
        } else {
          this.alertActionButtonFlg = false;
          this.alertActionButtonLabel = data[1];
          this.alertCancelButtonLabel = data[2];
        }
        this.showAlert();
      }
    );

    // ダイアログを管理する
    this.dialogSubscription = this.dialogService.dialogEventObservable$.subscribe(
      (data) => {
        this.dialogHeader = data[2];
        this.dialogContent = data[3];
        this.dialogClass = data[4];
        if (data[0] == "") {
          this.dialogActionButtonFlg = true;
          this.dialogCancelButtonLabel = "閉じる";
        } else {
          this.dialogActionButtonFlg = false;
          this.dialogActionButtonLabel = data[0];
          this.dialogCancelButtonLabel = data[1];
        }
        this.showDialog();
      }
    );

  }

  // 終了処理
  ngOnDestroy() {
    console.log("onDestroy");
    this.alertSubscription.unsubscribe();
  }



  /* ========== イベント ========== */

  // アラートの閉じるボタンをクリックしたとき
  public onClickAlertCancelButton() {
    this.hideAlert();
  }

  // アラートの実行ボタンをクリックしたとき
  public onClickAlertActionButton() {
    this.alertService.actionOnAlert();
    this.hideAlert();
  }

  // ダイアログの閉じるボタンをクリックしたとき
  public onClickDialogCancelButton() {
    this.hideDialog();
  }

  // ダイアログの実行ボタンをクリックしたとき
  public onClickDialogActionButton() {
    this.dialogService.actionOnDialog();
    this.hideDialog();
  }
  

    
  /* ========== アラート ========== */
  
  // アラートを表示する
  showAlert() {
    this.alertClass = "";
    var this_ = this;
    window.setTimeout(function() {
      console.log("timer");
      this_.alertClass = "show";
    },5);
    
  }
  
  // アラートを隠す
  hideAlert() {
    this.alertClass = "";
    var this_ = this;
    //this.alertClass = "hide";
    window.setTimeout(function() {
      this_.alertClass = "hide";
    },250);
  }



  /* ========== ダイアログ ========== */

  // ダイアログを表示する
  showDialog() {

    // ダイアログを表示する
    this.dialogFlg = true;
    
    // マスクを表示する
    this.showMask();
  
  }

  // ダイアログを隠す
  hideDialog() {
    
    // ダイアログを隠す
    this.dialogFlg = false;
    
    // マスクを非表示する
    this.hideMask();

  }


  /* ========== マスク ========== */

  // マスクを表示する
  showMask() {
    this.alertMaskFlg = false;
  }

  // マスクを隠す
  hideMask() {
    this.alertMaskFlg = true;
  }

}
