<aside [ngClass]="{'open': sidebarOpen,'show': sidebarShow}" class="nd-sidebar">

  <div class="spectrumSidebar">

    <div class="nd-sidebar-header">
      <a class="nd-sidebar-headerLink" href="/">
        <div class="nd-sidebar-logoContainer">
          <div class="nd-sidebar-title">
            <h2><img class="nd-sidebar-logo" src="/assets/images/logo.png"></h2>
          </div>
        </div>
      </a>
    </div>

    <div class="nd-sidebar-navigation">
      <nav>
        <ul class="spectrum-SideNav">
          <!--
          <li class="spectrum-SideNav-item nd-SideNav-item">
            <a routerLink="/home" (click)="onSideNavItem()" class="spectrum-SideNav-itemLink">ホーム</a>
          </li>
          -->
          <li class="spectrum-SideNav-item">
            <h2 class="spectrum-SideNav-heading" id="nav-heading-category-1"><!-- Village -->Calendar</h2>
            <ul class="spectrum-SideNav" aria-labelledby="nav-heading-category-1">
              <li class="spectrum-SideNav-item nd-SideNav-item">
                <a routerLink="/calendar" (click)="onSideNavItem()" class="spectrum-SideNav-itemLink">カレンダー登録・変更</a>
              </li>
              <li class="spectrum-SideNav-item nd-SideNav-item">
                <a routerLink="/calendar/setting" (click)="onSideNavItem()" class="spectrum-SideNav-itemLink">カレンダー設定</a>
              </li>
            </ul>
          </li>
          <li class="spectrum-SideNav-item">
            <h2 class="spectrum-SideNav-heading" id="nav-heading-category-2">その他</h2>
            <ul class="spectrum-SideNav" aria-labelledby="nav-heading-category-2">
              <li class="spectrum-SideNav-item nd-SideNav-item">
                <a (click)="onClickLogoutButton()" class="spectrum-SideNav-itemLink">ログアウト</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>

  </div>
</aside>

<nav id="app-bar" class="app-bar">
  <div class="item"><a class="spectrum-Button nd-Button-bar-item" (click)="scrollToTop()"><img src="/assets/svg/arrow_up.svg">ページの先頭へ</a></div>
  <div class="menu">
      <a [ngClass]="{'hide': sidebarOpen,'show': !sidebarOpen}" id="menu-open-btn" class="spectrum-Button nd-Button-menu-item" (click)="onClickMenuButton()"><img src="/assets/svg/menu.svg">メニュー</a>
      <a [ngClass]="{'show': sidebarOpen,'hide': !sidebarOpen}" id="menu-close-btn" class="spectrum-Button nd-Button-menu-item" (click)="onClickCloseButton()"><img src="/assets/svg/close.svg">閉じる</a>
  </div>
</nav>