import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Shop } from '../../../models/shop';

@Component({
  selector: 'calendar-setting-shop-list-item',
  templateUrl: './calendar-setting-shop-list-item.component.html'
})
export class CalendarSettingShopListItemComponent implements OnInit {

  @Output() event = new EventEmitter<any[]>();
  
  @Input() item: Shop;
  @Input() categories: string[];

  constructor() { }

  ngOnInit() {
  }

  onEditFolder() {
    var data: any[] = new Array(2);
    data[0] = "edit";
    data[1] = this.item;
    this.event.emit(data);
  }

  onDeleteFolder() {
    var data: any[] = new Array(2);
    data[0] = "delete";
    data[1] = this.item;
    this.event.emit(data);
  }

}