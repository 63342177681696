<div class="nd-CircleLoader-area">
  <div class="spectrum-CircleLoader spectrum-CircleLoader--indeterminate spectrum-CircleLoader--large">
    <div class="spectrum-CircleLoader-track"></div>
    <div class="spectrum-CircleLoader-fills">
      <div class="spectrum-CircleLoader-fillMask1">
        <div class="spectrum-CircleLoader-fillSubMask1">
          <div class="spectrum-CircleLoader-fill"></div>
        </div>
      </div>
      <div class="spectrum-CircleLoader-fillMask2">
        <div class="spectrum-CircleLoader-fillSubMask2">
          <div class="spectrum-CircleLoader-fill"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="nd-CircleLoader-label">読み込み中</div>
</div>
