import { Pipe, PipeTransform } from '@angular/core';

/*
 * 日付 20200120 を 2020年1月20日
 */

@Pipe({name: 'listDateFormatJP'})
export class ListDateFormatJPPipe implements PipeTransform {
  transform(value: string): string {
    var res = "";
    if (value == null || value == "" || value == undefined) {
      res = "ー";
    } else {
      var v_ = value.replace(/-/g, "");
      var y_ = v_.substr(0, 4);
      var m2_ = v_.substr(4, 2);
      var d2_ = v_.substr(6, 2);
      var m_ = parseInt(m2_, 10);
      var d_ = parseInt(d2_, 10);
      res = y_ + "年" + m_ + "月" + d_ + "日";
    }
    return res;
  }
}