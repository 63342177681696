<td class="spectrum-Table-cell calendar-setting-category-list-cell">
  <div class="nd-value-title">{{item.title}}</div>
  <div class="nd-value-status">{{item.status | calendarShopCategoryStatusCheck}}</div>
  <div class="nd-value-rank">{{item.rank}}</div>
  <div class="nd-value-action">
    <div class="nd-value-update">
      <button (click)="onEditFolder()" class="spectrum-ActionButton">
        <span class="spectrum-ActionButton-label">編集</span>
      </button>
    </div>
    <div class="nd-value-delete">
      <button (click)="onDeleteFolder()" class="spectrum-ActionButton">
        <span class="spectrum-ActionButton-label">削除</span>
      </button>
    </div>
  </div>
</td>