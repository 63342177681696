import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingEventSubject = new Subject<string>();
  public loadingEventObservable$ = this.loadingEventSubject.asObservable();

  constructor() { }

  public requestLoading(data: string) {
    this.loadingEventSubject.next(data);
  }
  
}