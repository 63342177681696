import { Component, OnInit, HostListener} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

// Service
import { LoadingService } from '../../../services/loading.service';
import { ShopcategoryService } from '../../../services/shopcategory.service';
import { AlertService } from '../../../services/alert.service';
import { DialogService } from '../../../services/dialog.service';

// Model
import { Shop } from '../../../models/shop';
import { Shopcategory } from '../../../models/shopcategory';

@Component({
  selector: 'calendar-setting-setting',
  templateUrl: './calendar-setting-setting.component.html'
})
export class CalendarSettingSettingComponent implements OnInit {

  // 登録フォーム
  registerForm: FormGroup;

  // ローディング
  circleLoaderClass = "hide";

  // コンテンツ
  contentsClass = "nd-col-xs-12 hide";

  // ボタンのラベル
  actionButtonLabel: String;

  // 編集しないボタンのクラス
  cancelButtonClass = "spectrum-Button nd-Button-cancel hide";

  // 今のモード
  currentMode = "register";

  // フォーム（必須項目）の状態を取得する
  get title() { return this.registerForm.get('title'); }
  get rank() { return this.registerForm.get('rank'); }

  /* ===== lifecycle ===== */

  constructor(
    private shopcategoryService: ShopcategoryService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {

    this.hideLoading();
    this.showContents();

    this.actionButtonLabel = "登録する";

    // フォームを初期化
    this.registerForm = this.formBuilder.group({
      category_id:['', Validators.required],
      title: ['', Validators.required],
      rank: ['', Validators.required]
    });
  }

  // 終了処理
  ngOnDestroy() {

  }

  /* ===== イベント ===== */

  // 編集しないボタンをタップ
  onClickCancelButton(){
    this.registerMode();
  }

  // 表示順を増やしたとき
  onIncreaseRank(event) {
    event.preventDefault();
    var n_:number = this.registerForm.controls.rank.value;
    if (n_ < 20) {
      this.registerForm.controls.rank.setValue(n_ + 1);
    }
  }
  
  // 表示順を減らしたとき
  onDecreaseRank(event) {
    event.preventDefault();
    var n_:number = this.registerForm.controls.rank.value;
    if (0 < n_) {
      this.registerForm.controls.rank.setValue(n_ - 1);
    }
  }
  
  // 送信する
  onSubmit() {
    console.log(this.currentMode);
    if (this.currentMode == "register") {
      if (this.registerForm.invalid) {
        console.log("invalid");
        console.log(this.registerForm.value);
        return;
      } else {
        console.log("submit");
        this.showRegisterDialog();
      }
    } else if (this.currentMode == "edit") {
      if (this.registerForm.invalid) {
        console.log("invalid");
        console.log(this.registerForm.value);
        return;
      } else {
        console.log("submit");
        //this.showUpdateDialog();
      }
    }
  }

/* ===== メソッド ===== */

  // ローディングを表示する
  showLoading() {
    this.circleLoaderClass = "show";
  }
  
  // ローディングを隠す
  hideLoading() {
    this.circleLoaderClass = "hide";
  }

  // コンテンツを表示する
  showContents() {
    this.contentsClass = "nd-col-xs-12 show";
  }

  // コンテンツを非表示する
  hideContents() {
    this.contentsClass = "nd-col-xs-12 hide";
  }

  // 新規登録モードに変更する
  registerMode() {
    this.currentMode = "register";
    this.actionButtonLabel = "登録する";
    // this.form.reset();
    // this.form.controls.title.setValue('');
    this.cancelButtonClass = "spectrum-Button nd-Button-cancel hide";
  }

  // 編集モードに変更する
  editMode() {
    this.currentMode = "edit";
    this.actionButtonLabel = "編集する";
    // this.registerForm.controls.title.setValue(this.item.title);
    this.cancelButtonClass = "spectrum-Button nd-Button-cancel";
  }

  /* ===== 確認ダイアログ ===== */

  /** 
   * 新規登録確認ダイアログを表示する
   * array[
   *  アクションボタンを使う場合は実行時のボタンのラベル名,
   *  アクションボタンを使う場合はキャンセル時のボタンのラベル名,
   *  タイトル,
   *  説明文
   * ]
   */
   showRegisterDialog() {
    // ダイアログに命令を登録する
    var order: string[] = ["app-photo-register", "register"];
    this.dialogService.setOrder(order);
    var dialogArray: string[] = ['登録する','登録しない','フォトライブラリーに画像を登録しますか?','登録する場合は「登録する」ボタンをクリックしてください。','nd-Button-register'];
    this.dialogService.requestDialog(dialogArray);
  }


  /* ===== 完了アラート ===== */

  /** 
   * アラートを表示する
   * array[
   *  アラートの種類（info, help, success, warning, error）,
   *  アクションボタンを使う場合は実行時のボタンのラベル名,
   *  アクションボタンを使う場合はキャンセル時のボタンのラベル名,
   *  タイトル,
   *  説明文
   * ]
   */
  showAlert() {
    var alertArray: string[] = ['success','','','フォトライブラリー','フォトライブラリーに画像を登録しました'];
    this.alertService.requestAlert(alertArray);
  }
  

}