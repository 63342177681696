import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  public header :Object;
  public headerPost: Object;

  constructor(
    private http: HttpClient,
    private authService: AuthService
    ) {
      this.header = {
        headers: new HttpHeaders().set('Authorization', `Bearer ${this.authService.currentAccount.token}`)
      };
  }
  
  // System以上のユーザーがすべての記録一覧を取得する
  getItems(){
    return this.http.get(environment.apiUrl + "/log", this.header);
    //return this.http.get<Yoyaku[]>(itemsUrl);
  }

  // ユーザーに紐づいた記録一覧を取得する
  getItemFormUserId(id: String){
    return this.http.get<any>(environment.apiUrl + "/log/list/" + id, this.header);
  }
  
  // ログインしたユーザーが自分の記録一覧を取得する
  getMyItems(){
    return this.http.get<any>(environment.apiUrl + "/log/list/", this.header);
  }



}
