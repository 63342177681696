<div class="nd-row">
            
    <div class="nd-col-xs-12">
  
      <div class="nd-page-header nd-botton-margin">
        <div class="nd-page-header-space"></div>
        <div class="nd-page-header-title">
          <h1 class="spectrum-Heading1">ホーム</h1>
        </div>
        <div class="nd-page-header-space"></div>
      </div><!-- /.nd-page-header -->
  
    </div><!-- /.nd-col-xs-12 -->

    <div [class]="contentsClass">
        <table class="spectrum-Table nd-full-width">
            <thead class="spectrum-Table-head">
                <tr>
                    <th class="spectrum-Table-headCell home-list">
                        <div class="nd-label-date">日時</div>
                        <div class="nd-label-body">内容</div>
                        <div class="nd-label-action">操作</div>
                    </th>
                </tr>
            </thead>
            <tbody class="spectrum-Table-body">
                <tr *ngFor="let item of items" class="spectrum-Table-row" tabindex="0">
                    <td class="spectrum-Table-cell home-list-cell">
                        <div class="nd-value-date">{{item.created_at | date:"yyyy年M月d日 HH:mm"}}</div>
                        <div class="nd-value-body">{{item.body_system}}</div>
                        <div class="nd-value-action" style="width:88px;">
                            <div *ngIf=has_link[item.id]>
                                <a class="spectrum-ActionButton" routerLink="{{item.link_system}}">
                                    <span class="spectrum-ActionButton-label">詳細</span>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div><!-- /.nd-row -->

<div [class]="circleLoaderClass">
    <app-circle-loader></app-circle-loader>
</div>