import { Pipe, PipeTransform } from '@angular/core';

/*
 * 1:有効 0:無効
 */

@Pipe({name: 'calendarShopCategoryStatusCheck'})
export class CalendarShopCategoryStatusCheckPipe implements PipeTransform {
  transform(value: number): string {
    status = "";
    switch (value) {
      case 1:
      status = "有効";
      break;
      case 0:
      status = "無効";
      break;
    }
    return status;
  }
}
