<div class="nd-row">
            
    <div class="nd-col-xs-12">

    <div class="nd-page-header nd-botton-margin">
        <div class="nd-page-header-space"></div>
        <div class="nd-page-header-title">
            <h1 class="spectrum-Heading1">カレンダー</h1>
        </div>
        <div class="nd-page-header-button"></div>
    </div><!-- /.nd-page-header -->

    </div><!-- /.nd-col-xs-12 -->

</div><!-- /.nd-row -->

<div class="nd-row nd-Calendar-shop-dropdown">

    <div class="nd-col-xs-12 nd-col-sm-6">
        <div class="spectrum-Dropdown">
            <button (click)="onShopDropdownTrigger($event)" id="shopTrigger" class="spectrum-FieldButton spectrum-Dropdown-trigger" aria-haspopup="true">
                <span class="spectrum-Dropdown-label">{{shopCurrentTitle}}</span>
                <svg class="spectrum-Icon spectrum-UIIcon-ChevronDownMedium spectrum-Dropdown-icon" focusable="false" aria-hidden="true">
                <use xlink:href="#spectrum-css-icon-ChevronDownMedium" />
                </svg>
            </button>
            <div [ngClass]="{'is-open': openShopDropdown}" class="spectrum-Popover spectrum-Popover--bottom spectrum-Dropdown-popover" style="max-width: 100%; z-index: 1;">
                <ul *ngFor="let shop of shopTitles; let i = index" class="spectrum-Menu" role="listbox">
                    
                    <li (click)='onShopDropdownChange(shopIds[i])' [ngClass]="{'is-selected': isShopSelectedArray[shopIds[i]]}" class="spectrum-Menu-item" tabindex="0">
                        <span class="spectrum-Menu-itemLabel">{{shop}}</span>
                        <svg class="spectrum-Icon spectrum-UIIcon-CheckmarkMedium spectrum-Menu-checkmark spectrum-Menu-itemIcon" focusable="false" aria-hidden="true">
                        <use xlink:href="#spectrum-css-icon-CheckmarkMedium"></use>
                        </svg>
                    </li>

                </ul>
            </div>
            
        </div>
    </div>
    
</div><!-- /.nd-row -->


<div class="nd-row">

    <div class="nd-col-xs-12 nd-col-sm-10 nd-col-sm-offset-1">

        <div class="spectrum-Calendar nd-page-calendar">
            <div class="spectrum-Calendar-header">
               <div class="spectrum-Calendar-title" role="heading" aria-live="assertive" aria-atomic="true">{{calendarTitle}}</div>
               <button [ngClass]="{'show': showPreviousMonthFlg}" (click)="setPreviousMonth()" id="calendar-previous-button" aria-label="Previous" title="Previous" class="spectrum-ActionButton spectrum-ActionButton--quiet spectrum-Calendar-prevMonth">
                 <svg class="spectrum-Icon spectrum-UIIcon-ChevronLeftLarge" focusable="false" aria-hidden="true">
                   <use xlink:href="#spectrum-css-icon-ChevronLeftLarge" />
                 </svg>
               </button>
               <div [ngClass]="{'hide': showPreviousMonthFlg}" style="width:64px;">&nbsp;</div>
               <button [ngClass]="{'show': showNextMonthFlg}" (click)="setNextMonth()" id="calendar-next-button" aria-label="Next" title="Next" class="spectrum-ActionButton spectrum-ActionButton--quiet spectrum-Calendar-nextMonth">
                 <svg class="spectrum-Icon spectrum-UIIcon-ChevronRightLarge" focusable="false" aria-hidden="true">
                   <use xlink:href="#spectrum-css-icon-ChevronRightLarge" />
                 </svg>
               </button>
            </div>
            <div [ngClass]="{'hide': calendarFlg}" class="spectrum-Calendar-body" role="grid" tabindex="0" aria-readonly="true" aria-disabled="false">
              <table role="presentation" class="spectrum-Calendar-table">
                 <thead role="presentation">
                    <tr role="row">
                       <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Sunday">日</abbr></th>
                       <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Monday">月</abbr></th>
                       <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Tuesday">火</abbr></th>
                       <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Wednesday">水</abbr></th>
                       <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Thursday">木</abbr></th>
                       <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Friday">金</abbr></th>
                       <th role="columnheader" scope="col" class="spectrum-Calendar-tableCell"><abbr class="spectrum-Calendar-dayOfWeek" title="Saturday">土</abbr></th>
                    </tr>
                 </thead>
                 <tbody role="presentation">
                    <tr role="row">
                       <td (click)="onClickDate(valueArray[0])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[0] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[0]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[0]].memo1 | calendarMemoCheck}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[0]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[0]].memo2 | calendarMemoCheck}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[0]"></div></td>
                       <td (click)="onClickDate(valueArray[1])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[1] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[1]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[1]].memo1 | calendarMemoCheck}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[1]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[1]].memo2 | calendarMemoCheck}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[1]"></div></td>
                       <td (click)="onClickDate(valueArray[2])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[2] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[2]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[2]].memo1 | calendarMemoCheck}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[2]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[2]].memo2 | calendarMemoCheck}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[2]"></div></td>
                       <td (click)="onClickDate(valueArray[3])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[3] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[3]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[3]].memo1 | calendarMemoCheck}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[3]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[3]].memo2 | calendarMemoCheck}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[3]"></div></td>
                       <td (click)="onClickDate(valueArray[4])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[4] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[4]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[4]].memo1 | calendarMemoCheck}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[4]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[4]].memo2 | calendarMemoCheck}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[4]"></div></td>
                       <td (click)="onClickDate(valueArray[5])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[5] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[5]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[5]].memo1 | calendarMemoCheck}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[5]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[5]].memo2 | calendarMemoCheck}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[5]"></div></td>
                       <td (click)="onClickDate(valueArray[6])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[6]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[6]].memo1 | calendarMemoCheck}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[6]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[6]].memo2 | calendarMemoCheck}}</span></div></td>
                    </tr>
                    <tr role="row">
                       <td (click)="onClickDate(valueArray[7])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[7]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[7]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[7]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[7]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[8])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[8]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[8]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[8]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[8]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[9])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[9]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[9]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[9]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[9]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[10])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[10]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[10]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[10]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[10]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[11])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[11]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[11]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[11]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[11]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[12])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[12]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[12]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[12]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[12]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[13])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[13]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[13]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[13]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[13]].memo2}}</span></div></td>
                    </tr>
                    <tr role="row">
                       <td (click)="onClickDate(valueArray[14])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[14]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[14]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[14]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[14]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[15])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[15]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[15]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[15]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[15]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[16])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[16]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[16]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[16]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[16]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[17])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[17]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[17]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[17]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[17]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[18])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[18]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[18]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[18]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[18]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[19])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[19]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[19]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[19]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[19]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[20])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[20]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[20]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[20]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[20]].memo2}}</span></div></td>
                    </tr>
                    <tr role="row">
                       <td (click)="onClickDate(valueArray[21])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[21]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[21]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[21]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[21]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[22])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[22]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[22]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[22]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[22]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[23])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[23]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[23]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[23]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[23]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[24])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[24]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[24]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[24]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[24]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[25])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[25]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[25]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[25]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[25]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[26])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[26]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[26]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[26]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[26]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[27])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[27]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[27]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[27]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[27]].memo2}}</span></div></td>
                    </tr>
                    <tr role="row">
                       <td (click)="onClickDate(valueArray[28])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!calendarFlg"><span class="nd-Calendar-date">{{dayArray[28]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[28]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[28]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[28]].memo2}}</span></div></td>
                       <td (click)="onClickDate(valueArray[29])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[29] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[29]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[29]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[29]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[29]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[29]"></div></td>
                       <td (click)="onClickDate(valueArray[30])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[30] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[30]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[30]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[30]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[30]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[30]"></div></td>
                       <td (click)="onClickDate(valueArray[31])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[31] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[31]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[31]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[31]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[31]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[31]"></div></td>
                       <td (click)="onClickDate(valueArray[32])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[32] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[32]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[32]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[32]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[32]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[32]"></div></td>
                       <td (click)="onClickDate(valueArray[33])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[33] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[33]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[33]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[33]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[33]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[33]"></div></td>
                       <td (click)="onClickDate(valueArray[34])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[34] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[34]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[34]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[34]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[34]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[34]"></div></td>
                    </tr>
                    <tr [ngClass]="{'hide': hideLastLine}" role="row">
                       <td (click)="onClickDate(valueArray[35])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[35] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[35]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[35]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[35]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[35]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[35]"></div></td>
                       <td (click)="onClickDate(valueArray[36])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[36] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[36]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[36]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[36]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[36]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[36]"></div></td>
                       <td (click)="onClickDate(valueArray[37])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[37] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[37]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[37]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[37]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[37]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[37]"></div></td>
                       <td (click)="onClickDate(valueArray[38])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[38] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[38]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[38]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[38]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[38]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[38]"></div></td>
                       <td (click)="onClickDate(valueArray[39])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[39] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[39]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[39]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[39]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[39]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[39]"></div></td>
                       <td (click)="onClickDate(valueArray[40])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[40] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[40]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[40]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[40]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[40]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[40]"></div></td>
                       <td (click)="onClickDate(valueArray[41])" class="spectrum-Calendar-tableCell"><div class="nd-Calendar-tableCell" *ngIf="!outsideDayArray[41] && !calendarFlg"><span class="nd-Calendar-date">{{dayArray[41]}}</span><span class="nd-Calendar-memo1">{{items[dayArray[41]].memo1}}</span><span class="nd-Calendar-status"><img src="/assets/images/calendar_status_{{items[dayArray[41]].status | calendarStatusCheck}}.png"></span><span class="nd-Calendar-memo2">{{items[dayArray[41]].memo2}}</span></div><div class="nd-Calendar-blank-date" *ngIf="outsideDayArray[41]"></div></td>
                    </tr>
                 </tbody>
              </table>
            </div>
        </div>

    </div><!-- /.nd-col-xs-12 -->

</div><!-- /.nd-row -->


<div [class]="circleLoaderClass">
<app-circle-loader></app-circle-loader>
</div>


<div [ngClass]="{'hide': calendarMaskFlg}" class="mask"></div>


<div [ngClass]="{'is-open': calendarDialogFlg}" class="spectrum-Dialog spectrum-Dialog--alert nd-Calendar-dialog">
    <form [formGroup]="registerForm" class="spectrum-Form spectrum-Form--labelsAbove">
        <div class="spectrum-Dialog-header">
        <h2 class="spectrum-Dialog-title">{{this.currentDate | calendarDateFormatJP}}</h2>
        </div>
        <div class="spectrum-Dialog-content">
            <div class="spectrum-Form-item">
                <label for="memo1" class="spectrum-FieldLabel">メモ（上）</label>
                <input id="memo1" formControlName="memo1" type="text" placeholder="全角6文字まで" value="" class="spectrum-Textfield">
            </div>
            <div class="spectrum-Form-item">
                <label class="spectrum-FieldLabel">状況</label>
                <div class="nd-Calendar-radio-buttons">
                    <div [ngClass]="{'checked': calendarStatus[1]}" (click)="onStatusChange(1)" class="spectrum-Radio nd-Calendar-radio">
                        <span class="spectrum-Radio-button"></span>
                        <label class="spectrum-Radio-label" for="radio-0">営業日</label>
                    </div>
                    <div [ngClass]="{'checked': calendarStatus[2]}" (click)="onStatusChange(2)" class="spectrum-Radio nd-Calendar-radio">
                        <span class="spectrum-Radio-button"></span>
                        <label class="spectrum-Radio-label" for="radio-2">ご注意</label>
                    </div>
                    <div [ngClass]="{'checked': calendarStatus[3]}" (click)="onStatusChange(3)" class="spectrum-Radio nd-Calendar-radio">
                        <span class="spectrum-Radio-button"></span>
                        <label class="spectrum-Radio-label" for="radio-1">休業日</label>
                    </div>
                    <div [ngClass]="{'checked': calendarStatus[98]}" (click)="onStatusChange(98)" class="spectrum-Radio nd-Calendar-radio">
                        <span class="spectrum-Radio-button"></span>
                        <label class="spectrum-Radio-label" for="radio-3">未定</label>
                    </div>
                    <div [ngClass]="{'checked': calendarStatus[99]}" (click)="onStatusChange(99)" class="spectrum-Radio nd-Calendar-radio">
                        <span class="spectrum-Radio-button"></span>
                        <label class="spectrum-Radio-label" for="radio-4">その他</label>
                    </div>
                </div>
                <input id="zan" formControlName="status" type="hidden" value="">
                <input formControlName="shop_id" type="hidden" value="">
            </div>

            <div class="spectrum-Form-item">
                <label for="memo2" class="spectrum-FieldLabel">メモ（下）</label>
                <input id="memo2" formControlName="memo2" type="text" placeholder="全角6文字まで" value="" class="spectrum-Textfield">
            </div>
        </div>
        <div class="spectrum-Dialog-footer">
            <button (click)="onClickCalendarDialogCancelButton()" class="spectrum-Button nd-Button-cancel">更新しない</button>
            <button (click)="onSubmit()" class="spectrum-Button nd-Button-update" >更新する</button>
        </div>
    </form>
  </div>